<template>
    <div class="content-form">
        <a-form :form="form">
            <a-row :gutter="24">
                <a-col :span="4" :lg="6" :xxl="3">
                    <a-form-item label="Tên">
                        <a-input
                            allow-clear
                            v-decorator="['fullName', { initialValue: '' }]"
                            style="width: 100%"
                        />
                    </a-form-item>
                </a-col>

                <a-col :span="4" :lg="6" :xxl="3">
                    <a-form-item label="Số điện thoại">
                        <a-input
                            allow-clear
                            v-decorator="['phone', { initialValue: '' }]"
                            style="width: 100%"
                        />
                    </a-form-item>
                </a-col>

                <a-col :span="4" :lg="6" :xxl="3">
                    <a-form-item label="CMND">
                        <a-input
                            allow-clear
                            v-decorator="['idNumber', { initialValue: '' }]"
                            style="width: 100%"
                        />
                    </a-form-item>
                </a-col>

                <!-- <a-col :span="4" :lg="6" :xxl="3">
                    <a-form-item label="Khu vực">
                        <a-select
                            show-search
                            option-filter-prop="children"
                            :filter-option="filterOption"
                            v-decorator="['area', { initialValue: 'ALL' }]"
                            style="width: 100%"
                        >
                            <a-select-option value="ALL">
                                Tất cả
                            </a-select-option>
                            <a-select-option
                                v-for="city in listCity"
                                :key="city.id"
                                :value="city.id"
                            >
                                {{ city.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col> -->

                <a-col :span="4" :lg="6" :xxl="3">
                    <a-form-item label="Vị trí">
                        <a-select
                            option-filter-prop="children"
                            :filter-option="filterOption"
                            v-decorator="['position', { initialValue: 'ALL' }]"
                            style="width: 100%"
                        >
                            <a-select-option value="ALL">
                                Tất cả
                            </a-select-option>
                            <a-select-option
                                v-for="position in listPosition"
                                :key="position.id"
                                :value="position.id"
                            >
                                {{ position.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <!-- <a-col :span="4" :lg="6" :xxl="3">
                    <a-form-item label="Salon">
                        <a-input v-decorator="['salon']" style="width: 100%" />
                    </a-form-item>
                </a-col> -->

                <!-- <a-col :span="4" :lg="6" :xxl="3">
                    <a-form-item label="Nguồn TD">
                        <a-select
                            show-search
                            option-filter-prop="children"
                            :filter-option="filterOption"
                            v-decorator="['source', { initialValue: 'ALL' }]"
                            style="width: 100%"
                        >
                            <a-select-option value="ALL">
                                Tất cả
                            </a-select-option>
                            <a-select-option
                                v-for="source in listSource"
                                :key="source.id"
                                :value="source.id"
                            >
                                {{ source.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col> -->

                <a-col :span="4" :lg="6" :xxl="3">
                    <a-form-item label="Trạng thái">
                        <a-select
                            v-decorator="['status', { initialValue: 'ALL' }]"
                            style="width: 100%"
                        >
                            <a-select-option value="ALL">
                                Tất cả
                            </a-select-option>
                            <a-select-option value="NEW">
                                Hồ sơ mới
                            </a-select-option>
                            <!-- <a-select-option value="PASS">
                                Đạt
                            </a-select-option> -->
                            <a-select-option value="FAIL">
                                Không đạt
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col :span="4" class="form-filter_button">
                    <a-form-item>
                        <a-button
                            type="primary"
                            @click="handleSubmit"
                            icon="search"
                        >
                            Xem kết quả
                        </a-button>
                    </a-form-item>
                </a-col>

                <a-col :span="4" class="form-filter_button">
                    <a-form-item>
                        <a-button @click="handleReset">Xóa tìm kiếm</a-button>
                    </a-form-item>
                </a-col>

                <!-- <a-col :span="4" class="form-filter_button">
                    <a-form-item>
                        <a-button
                            type="default"
                            icon="download"
                            @click="handleClickExport"
                        >
                            Xuất excel
                        </a-button>
                    </a-form-item>
                </a-col> -->
            </a-row>
        </a-form>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            form: this.$form.createForm(this, { name: 'FormFilter' }),
            roundSelected: {
                0: 'ALL',
                1: 'ALL',
                2: 'ALL',
            },
            // currentFilter: {},
        };
    },

    computed: {
        ...mapState('source', {
            listSource: 'data',
        }),
        ...mapState('position', {
            listPosition: 'data',
        }),
        ...mapState('city', {
            listCity: 'data',
        }),
        ...mapState('round', {
            listRound: 'data',
        }),
    },

    methods: {
        getValueFilter(values) {
            const { position, status, fullName, phone, idNumber } = values;
            let filterValue = {
                fullName: fullName || null,
                phone: phone || null,
                idNumber: idNumber || null,
            };

            if (position !== 'ALL') {
                filterValue.staffType = {
                    id: position,
                };
            }

            if (status !== 'ALL') {
                switch (status) {
                    case 'NEW': {
                        filterValue.status = {
                            pending: true,
                        };
                        break;
                    }
                    case 'PASS': {
                        filterValue.status = {
                            pending: false,
                            passed: true,
                        };
                        break;
                    }
                    default:
                        filterValue.status = {
                            pending: false,
                            passed: false,
                        };
                        break;
                }
            }
            return filterValue;
        },

        handleSubmit() {
            // e.preventDefault();
            this.form.validateFields((err, values) => {
                const filterValue = this.getValueFilter(values);
                this.$emit('click-filter', filterValue);
            });
        },

        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            );
        },

        handleReset() {
            this.form.resetFields();
            this.$emit('click-filter', {});
        },

        // handleClickExport() {
        //     this.$emit('click-export', this.currentFilter);
        // },
    },
};
</script>

<style lang="scss">
.content-form {
    padding: 10px 15px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    /* margin-top: 20px; */

    .ant-form-item {
        margin-bottom: 10px;
        .ant-form-item-label {
            min-width: 140px;
            text-align: left;
            line-height: unset;
        }

        .ant-form-item-control-wrapper {
            width: 100%;
        }
    }

    .form-filter_button {
        width: auto;
        padding-top: 22px;
    }
}
</style>
